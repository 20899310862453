@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,600;1,200&family=Righteous&display=swap');

html {
  width: 100vw;
  overflow-y: overlay;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: all 0.5s ease;
  z-index: 5;
}

.site-wrapper {
  position: absolute;
  width: 100vw;
  height: auto;
  overflow-x: hidden;
  font-family: 'Josefin Sans', serif;
}

::placeholder {
  font-style: italic;
  color: black;
}

a {
  text-decoration-line: none;
  :active {
    text-decoration-line: none;
  }
}

.object-bottom {
  width: 100vw;
  height: 100vh;
  background-size: contain;
  background-position: bottom;
}